import {AppState} from '../states/app.state';
import {createSelector} from '@ngrx/store';
import {firmaEntitiesAdapter} from '../adapters/firma-entities.adapters';
import {identity} from 'rxjs';


export class FirmaEntitiesSelectors {

  private static adapterSelectors = firmaEntitiesAdapter.getSelectors();

  private static select = {
    entities: (state: AppState) => state.firmaEntities,
    isLoaded: (state: AppState) => state.firmaEntities.firmenLoaded,
  };

  public static all = createSelector(
    this.select.entities,
    this.adapterSelectors.selectAll,
  );

  public static firmaById = (firmaId: string) => createSelector(
    this.all,
    firmaDtos => firmaDtos.find(firmaDto => {
      return firmaDto.firmaId === firmaId;
    }),
  );

  public static firmenLoaded = createSelector(
    this.select.isLoaded,
    identity,
  );

}
