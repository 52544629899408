/**
 * Schluesseldaten
 * Ein API zum Bereitstellen von Schluesseldaten
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum BundeslandDTO {
    SCHLESWIG_HOLSTEIN = '1   Schleswig-Holstein',
    HAMBURG = '2   Hamburg',
    NIEDERSACHSEN = '3   Niedersachsen',
    BREMEN = '4   Bremen',
    NORDRHEIN_WESTFALEN = '5   Nordrhein-Westfalen',
    HESSEN = '6   Hessen',
    RHEINLAND_PFALZ = '7   Rheinland-Pfalz',
    BADEN_WUERTTEMBERG = '8   Baden-Württemberg',
    BAYERN = '9   Bayern',
    SAARLAND = '10    Saarland',
    BERLIN = '11    Berlin',
    BRANDENBURG = '12    Brandenburg',
    MECKLENBURG_VORPOMMERN = '13    Mecklenburg-Vorpommern',
    SACHSEN = '14    Sachsen',
    SACHSEN_ANHALT = '15    Sachsen-Anhalt',
    THUERINGEN = '16    Thüringen'
};

