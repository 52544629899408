import {DestroyRef, inject, Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, Observable} from 'rxjs';
import {PortalMessageBroker} from './portal-message-broker';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


export enum WellKnownShellProperties {
  APP_MODE_NORMAL = 'shell:app-mode:normal',
  APP_MODE_POPUP = 'shell:app-mode:popup',
  APP_MODE_WIDGET = 'shell:app-mode:widget',
  EMBEDDED_EMBEDDED = 'shell:embedded:embedded',
  EMBEDDED_EMBEDDING = 'shell:embedded:embedding',
}

export enum WellKnownJustFarmingProperties {
  ANALYTICS = 'vendor:de.just-farming:analytics',
  ERROR_REPORTING = 'vendor:de.just-farming:errorReporting',
}

export type WellKnownProperties = WellKnownShellProperties | WellKnownJustFarmingProperties;
export type PropertyKey = string | WellKnownProperties;

export type Property = boolean | string | number | undefined;
export type ArrayProperty = Property[];
export type ContextInfoProperty = Property | ArrayProperty;

export interface ContextInfo {
  properties: Map<PropertyKey, ContextInfoProperty>;
}


@Injectable({
  providedIn: 'root'
})
export class ContextInfoService {

  private _destroyRef = inject(DestroyRef);
  private _logger = inject(NGXLogger);
  private _portalMessageBroker = inject(PortalMessageBroker);

  private readonly _contextInfo$ = new BehaviorSubject<ContextInfo>({
    properties: new Map(),
  });

  constructor() {
    this._portalMessageBroker.registerContextInfoCallback(contextInfo => {
      this._contextInfo$.next(contextInfo);

      this._logger.debug('ContextInfoService: new context info', this._contextInfo$.value);
    });
  }

  get contextInfo(): ContextInfo {
    return this._contextInfo$.value;
  }

  get contextInfo$(): Observable<ContextInfo> {
    return this._contextInfo$.asObservable().pipe(
      takeUntilDestroyed(this._destroyRef),
    );
  }

  /**
   * Legt den Wert einer geteilten Eigenschaft fest.
   *
   * Steht anderen Applications über das ContextInfo Objekt zur Verfügung.
   *
   * @param key eindeutiger Identifikator
   * @param value Wert zum angegebenen Schlüssel
   */
  public submitProperty(key: string, value: ContextInfoProperty): void {
    if (this._portalMessageBroker.isRunningInPortal()) {
      this._portalMessageBroker.submitContextInfoProperty(key, value);
    }
  }

}
