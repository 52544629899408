import {AppState} from '../states/app.state';
import {createSelector} from '@ngrx/store';
import {identity} from 'rxjs';
import {finanzamtEntitiesAdapter} from '../adapters/finanzamt-entities.adapters';


export class FinanzamtEntitiesSelectors {

  private static adapterSelectors = finanzamtEntitiesAdapter.getSelectors();

  private static select = {
    entities: (state: AppState) => state.finanzamtEntities,
    isLoaded: (state: AppState) => state.finanzamtEntities.finanzaemterLoaded,
  };

  public static all = createSelector(
    this.select.entities,
    this.adapterSelectors.selectAll,
  );

  public static finanzaemterLoaded = createSelector(
    this.select.isLoaded,
    identity,
  );

  public static bundeslandDtoByFinanzamtsschluessel = (finanzamtsschluessel: number) => createSelector(
    this.all,
    finanzamtDtos => finanzamtDtos.find(finanzamt => {
      return finanzamt.finanzamtsschluessel == finanzamtsschluessel;
    })?.bundesland,
  );

}
