import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SchluesseldatenService} from '../../openapi/schluesseldaten-openapi';
import {concatMap, of, switchMap} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {SentryActions} from '../actions/sentry.actions';
import {FinanzamtEntitiesActions} from '../actions/finanzamt-entities.actions';


@Injectable()
export class FinanzamtEntitiesEffects {

  constructor(
    private actions$: Actions,
    private schluesseldatenService: SchluesseldatenService,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
  ) {
  }

  /**
   * Lädt die Finanzämter vom Service.
   */
  readonly readFinanzaemter$ = createEffect(
    () => this.actions$.pipe(
      ofType(FinanzamtEntitiesActions.readFinanzaemter),
      switchMap(() => {
        return this.schluesseldatenService.readFinanzaemter().pipe(
          map(finanzamtDtos => {
            this.logger.debug(
              'read finanzämter succeeded.',
            );

            finanzamtDtos.sort((a, b) => {
              return (a.finanzamtsbezeichnung || '').localeCompare((b.finanzamtsbezeichnung || ''), 'de');
            });

            return FinanzamtEntitiesActions.readFinanzaemterSuccess({
              finanzamtDtos,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            concatMap(error => {
              this.logger.error(
                'read finanzämter failed. error: ',
                error,
              );

              return [
                FinanzamtEntitiesActions.readFinanzaemterFailure({
                  error: error,
                }),
                SentryActions.captureException({
                  error,
                  extras: {}
                }),
              ];
            }),
          ))
        );
      }),
    ),
  );

  /**
   * Error-Handling für das Laden der Finanzämter.
   */
  readonly readFinanzaemterFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FinanzamtEntitiesActions.readFinanzaemterFailure),
      map(({error}) => {
        this.snackbar.open(
          'Fehler bei dem Laden der Finanzamts-Informationen. Bitte probiere es später erneut.',
          undefined,
          {
            duration: 5000,
          });
      }),
    ),
    {dispatch: false},
  );

}
