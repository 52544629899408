import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {inject, Injectable} from '@angular/core';
import {AppState} from '../store/states/app.state';
import {FirmaEntitiesSelectors} from '../store/selectors/firma-entities.selectors';
import {FirmaEntitiesActions} from '../store/actions/firma-entities.actions';


@Injectable({
  providedIn: 'root'
})
export class FirmaResolver {

  private _store = inject(Store<AppState>);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this._store.select(FirmaEntitiesSelectors.firmenLoaded).subscribe(loaded => {
      if (!loaded) {
        this._store.dispatch(FirmaEntitiesActions.readFirmen());
      }
    });
  }
}
