import {createAction, props} from '@ngrx/store';
import {FinanzamtDTO} from '../../openapi/schluesseldaten-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class FinanzamtEntitiesActions {

  public static readFinanzaemter = createAction(
    '[Finanzamt Resolver] Read finanzaemter.',
  );

  public static readFinanzaemterSuccess = createAction(
    '[Finanzamt Entities] Read finanzaemter successfully.',
    props<{
      finanzamtDtos: FinanzamtDTO[],
    }>()
  );

  public static readFinanzaemterFailure = createAction(
    '[Finanzamt Entities] Read finanzaemter failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>()
  );

}
