<div *ngIf="inhaberId !== undefined && showIconLinkWrapper" class="icon-link-wrapper">

  <!-- INFO: Zukünftig werden die Links vermutlich benötigt, aus diesem Grund vorhanden. -->
  <!--  <jf-link-->
  <!--    *ngFor="let navigationLink of navigationLinks"-->
  <!--    variant="textlink-icon"-->
  <!--    [routerLink]="[navigationLink.route, 'inhaber', inhaberId]"-->
  <!--    [text]="navigationLink.label"-->
  <!--    [iconName]="navigationLink.iconName"-->
  <!--    iconPosition="before"-->
  <!--    routerLinkActive="active"-->
  <!--    i18n-text-->
  <!--  ></jf-link>-->

  <jf-button
    *ngIf="buttonRoute && buttonLabel"
    [variant]="buttonVariant"
    [icon]="buttonIcon"
    [text]="buttonLabel"
    iconPosition="left"
    (click)="doButtonNavigation()"
  ></jf-button>
</div>

<div class="content-wrapper">
  <router-outlet></router-outlet>
</div>
