/**
 * Partner
 * Eine API zum Importieren, Aktualisieren und Lesen von Partnern in ADNOVA+. Es können die Partnertypen Kunde, Lieferant und Mandant angelegt werden. Mandanten können zudem aktualisiert werden. Es können sowohl Partner in der eigenen Organisation, als auch Partner im Unternehmen eines Mandanten angelegt werden. Zum Anlegen eines Partners müssen alle benötigten Pflichtangaben mitgeschickt werden. An zusätzlichen Angaben können noch Informationen zur Steuernummer mit angelegt werden. Weiterhin können alle Partner ausgelesen werden, auf die der Benutzer Zugriffsrechte besitzt. 
 *
 * The version of the OpenAPI document: ${project.version}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { FirmaExtendedReadResponseDTO } from '../model/models';
import { FirmaReadResponseDTO } from '../model/models';
import { FirmaUpdateRequestDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class FirmenService {

    protected basePath = 'https://api.adnova.de/partner-service';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Lesen einer einzelnen Firma
     * Gibt eine Firma mit den Stammdaten zurück. Die Firma muss zu der eigenen Organisation gehören und das Recht zum Bearbeiten muss vorliegen. 
     * @param firmaId Identifikation einer Firma.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readFirma(firmaId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<FirmaExtendedReadResponseDTO>;
    public readFirma(firmaId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<FirmaExtendedReadResponseDTO>>;
    public readFirma(firmaId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<FirmaExtendedReadResponseDTO>>;
    public readFirma(firmaId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (firmaId === null || firmaId === undefined) {
            throw new Error('Required parameter firmaId was null or undefined when calling readFirma.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (user-to-service-oauth) required
        credential = this.configuration.lookupCredential('user-to-service-oauth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<FirmaExtendedReadResponseDTO>(`${this.configuration.basePath}/api/v1/firmen/${encodeURIComponent(String(firmaId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen von Firmen mit erweiterten Informationen
     * Gibt alle Firmen auf Basis der Berechtigungen des Benutzers, der den Endpunkt aufruft, zurück. Die Firmen müssen zur der eigenen Organisation gehören und das Recht zum Bearbeiten muss vorliegen. Es werden Stammdaten zu den Firmen mit geladen. Wenn diese Informationen nicht benötigt werden, sollte aus Performancegründen der Aufruf \&#39;api/v1/firmen\&#39; zum Laden von Firmen genutzt werden. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readFirmenExtendedList(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<FirmaExtendedReadResponseDTO>>;
    public readFirmenExtendedList(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<FirmaExtendedReadResponseDTO>>>;
    public readFirmenExtendedList(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<FirmaExtendedReadResponseDTO>>>;
    public readFirmenExtendedList(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (user-to-service-oauth) required
        credential = this.configuration.lookupCredential('user-to-service-oauth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<FirmaExtendedReadResponseDTO>>(`${this.configuration.basePath}/api/v1/firmen/extended`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen von Firmen
     * Gibt alle Firmen auf Basis der Berechtigungen des Benutzers, der den Endpunkt aufruft, zurück. Es werden nur die Firmen der eigenen Organisation zurückgegeben. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readFirmenList(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<FirmaReadResponseDTO>>;
    public readFirmenList(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<FirmaReadResponseDTO>>>;
    public readFirmenList(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<FirmaReadResponseDTO>>>;
    public readFirmenList(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (user-to-service-oauth) required
        credential = this.configuration.lookupCredential('user-to-service-oauth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<FirmaReadResponseDTO>>(`${this.configuration.basePath}/api/v1/firmen`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aktualisieren einer Firma
     * Aktualisieren einer Firma. Es können allgemeine Daten geändert werden. Die Firma muss zu der eigenen Organisation gehören und das Recht zum Bearbeiten muss vorliegen. Die Firma wird mit den Werten überschrieben, die hier mitgegeben werden. Wenn ein Wert nicht gesetzt ist, wird dieser dann zur Firma entfernt. 
     * @param firmaId Identifikation einer Firma.
     * @param firmaUpdateRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFirma(firmaId: string, firmaUpdateRequestDTO: FirmaUpdateRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public updateFirma(firmaId: string, firmaUpdateRequestDTO: FirmaUpdateRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public updateFirma(firmaId: string, firmaUpdateRequestDTO: FirmaUpdateRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public updateFirma(firmaId: string, firmaUpdateRequestDTO: FirmaUpdateRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (firmaId === null || firmaId === undefined) {
            throw new Error('Required parameter firmaId was null or undefined when calling updateFirma.');
        }
        if (firmaUpdateRequestDTO === null || firmaUpdateRequestDTO === undefined) {
            throw new Error('Required parameter firmaUpdateRequestDTO was null or undefined when calling updateFirma.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (user-to-service-oauth) required
        credential = this.configuration.lookupCredential('user-to-service-oauth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/firmen/${encodeURIComponent(String(firmaId))}`,
            firmaUpdateRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
