import {createAction, props} from '@ngrx/store';
import {FirmaExtendedReadResponseDTO, FirmaUpdateRequestDTO} from '../../openapi/partner-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class FirmaEntitiesActions {

  public static loadFirmaByIdIfAbsent = createAction(
    '[Edit] Load firma by ID if absent.',
    props<{
      firmaId: string;
    }>(),
  );

  public static readFirma = createAction(
    '[Edit] Read firma.',
    props<{
      firmaId: string,
    }>()
  );

  public static readFirmaSuccess = createAction(
    '[Firma Entities] Read firma successfully.',
    props<{
      firmaDto: FirmaExtendedReadResponseDTO,
    }>()
  );

  public static readFirmaFailure = createAction(
    '[Firma Entities] Read firma failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>()
  );

  public static readFirmen = createAction(
    '[Firma Resolver] Read firmen.',
  );

  public static readFirmenSuccess = createAction(
    '[Firma Entities] Read firmen successfully.',
    props<{
      firmenDtos: FirmaExtendedReadResponseDTO[],
    }>()
  );

  public static readFirmenFailure = createAction(
    '[Edit] Read firmen failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>()
  );

  public static updateFirma = createAction(
    '[Firma Effect] Update firma.',
    props<{
      firmaId: string,
      requestDto: FirmaUpdateRequestDTO,
    }>()
  );

  public static updateFirmaSuccess = createAction(
    '[Firma Entities] Updated firma successfully.',
    props<{
      firmaDto: FirmaExtendedReadResponseDTO,
    }>(),
  );

  public static updateFirmaFailure = createAction(
    '[Firma Entities] Update firma failed.',
    props<{
      error: any,
    }>(),
  );

}
