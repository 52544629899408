/**
 * Partner
 * Eine API zum Importieren, Aktualisieren und Lesen von Partnern in ADNOVA+. Es können die Partnertypen Kunde, Lieferant und Mandant angelegt werden. Mandanten können zudem aktualisiert werden. Es können sowohl Partner in der eigenen Organisation, als auch Partner im Unternehmen eines Mandanten angelegt werden. Zum Anlegen eines Partners müssen alle benötigten Pflichtangaben mitgeschickt werden. An zusätzlichen Angaben können noch Informationen zur Steuernummer mit angelegt werden. Weiterhin können alle Partner ausgelesen werden, auf die der Benutzer Zugriffsrechte besitzt. 
 *
 * The version of the OpenAPI document: ${project.version}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Gibt an um was für eine Adresse es sich handelt. Je nach AdressArt sind andere Angaben bei der Anlage notwendig. 
 */
export enum AdressartDTO {
    StrassenAdresse = 'StrassenAdresse',
    PostfachAdresse = 'PostfachAdresse',
    GrosskundenAdresse = 'GrosskundenAdresse'
};

