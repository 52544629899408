import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {inject, Injectable} from '@angular/core';
import {AppState} from '../store/states/app.state';
import {
  FinanzamtEntitiesSelectors
} from '../store/selectors/finanzamt-entities.selectors';
import {FinanzamtEntitiesActions} from '../store/actions/finanzamt-entities.actions';


@Injectable({
  providedIn: 'root'
})
export class FinanzamtResolver {

  private _store = inject(Store<AppState>);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this._store.select(FinanzamtEntitiesSelectors.finanzaemterLoaded).subscribe(loaded => {
      if (!loaded) {
        this._store.dispatch(FinanzamtEntitiesActions.readFinanzaemter());
      }
    });
  }
}
