/**
 * Schluesseldaten
 * Ein API zum Bereitstellen von Schluesseldaten
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum AfaArtDTO {
    STEUERLICH = '1   Steuerlich',
    BETRIEBSWIRTSCHAFTLICH = '2   Betriebswirtschaftlich',
    SONDER_AFA = '3   Sonder-Afa',
    ZUSCHREIBUNGEN = '4   Zuschreibungen',
    HANDELSGESETZBUCH = '6   Handelsgesetzbuch'
};

