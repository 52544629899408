import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {inject, Injectable} from '@angular/core';
import {AppState} from '../store/states/app.state';
import {LandEntitiesSelectors} from '../store/selectors/land-entities.selectors';
import {LandEntitiesActions} from '../store/actions/land-entities.actions';


@Injectable({
  providedIn: 'root'
})
export class LandResolver {

  private _store = inject(Store<AppState>);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this._store.select(LandEntitiesSelectors.laenderLoaded).subscribe(loaded => {
      if (!loaded) {
        this._store.dispatch(LandEntitiesActions.readLaender());
      }
    });
  }
}
