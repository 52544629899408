import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {AppState} from '../states/app.state';
import {initialJfLibState, jfLibReducers} from '@adnova/jf-ng-components';
import {firmaEntitiesReducer, initialFirmaEntitiesState} from './firma-entities.reducers';
import {initialLandEntitiesState, landEntitiesReducer} from './land-entities.reducers';
import {
  initialFinanzamtEntitiesState,
  finanzamtEntitiesReducer
} from './finanzamt-entities.reducers';
import {firmaEditReducer, initialFirmaEditState} from './firma-edit.reducers';


export const initialAppState: AppState = {
  ...initialJfLibState,
  firmaEntities: initialFirmaEntitiesState,
  landEntities: initialLandEntitiesState,
  finanzamtEntities: initialFinanzamtEntitiesState,
  firmaEdit: initialFirmaEditState,
};

export const reducers: ActionReducerMap<AppState> = {
  ...jfLibReducers,
  firmaEntities: firmaEntitiesReducer,
  landEntities: landEntitiesReducer,
  finanzamtEntities: finanzamtEntitiesReducer,
  firmaEdit: firmaEditReducer,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
