import {ButtonVariant, InhaberEntitiesSelectors, RouterSelectors} from '@adnova/jf-ng-components';
import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {combineLatest} from 'rxjs';
import {Router} from '@angular/router';
import {NavigationLink} from './navigation-link.interface';
import {IconName} from '@adnova/jf-ng-components/lib/generic/media/icon/icons';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/states/app.state';


@Component({
  selector: 'app-navigation',
  templateUrl: './app-navigation.component.html',
  styleUrls: ['./app-navigation.component.scss']
})
export class AppNavigationComponent implements OnInit {

  private _destroyRef = inject(DestroyRef);
  private _store = inject(Store<AppState>);
  private _router = inject(Router);

  private _buttonLabel = '';
  private _buttonRoute = '';
  private _buttonIcon?: IconName;
  private _buttonVariant: ButtonVariant;
  private _navigationLinks: NavigationLink[] = [];
  private _showIconLinkWrapper = true;
  protected inhaberId?: string;

  get buttonLabel(): string {
    return this._buttonLabel;
  }

  set buttonLabel(value: string) {
    this._buttonLabel = value;
  }

  get buttonRoute(): string {
    return this._buttonRoute;
  }

  set buttonRoute(value: string) {
    this._buttonRoute = value;
  }

  get buttonIcon(): IconName | undefined {
    return this._buttonIcon;
  }

  set buttonIcon(value: IconName | undefined) {
    this._buttonIcon = value;
  }

  get buttonVariant(): ButtonVariant {
    return this._buttonVariant;
  }

  set buttonVariant(value: ButtonVariant) {
    this._buttonVariant = value;
  }

  get navigationLinks(): NavigationLink[] {
    return this._navigationLinks;
  }

  set navigationLinks(value: NavigationLink[]) {
    this._navigationLinks = value;
  }

  get showIconLinkWrapper(): boolean {
    return this._showIconLinkWrapper;
  }

  ngOnInit(): void {
    this._store.select(RouterSelectors.dataByKey('buttonLabel')).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe((buttonLabel) => {
      this.buttonLabel = buttonLabel || '';
    });

    this._store.select(RouterSelectors.dataByKey('buttonRoute')).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe((buttonRoute) => {
      this.buttonRoute = buttonRoute || '';
    });

    this._store.select(RouterSelectors.dataByKey<IconName>('buttonIcon')).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe((buttonIcon) => {
      this.buttonIcon = buttonIcon;
    });

    this._store.select(RouterSelectors.dataByKey<ButtonVariant>('buttonVariant')).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe((buttonVariant) => {
      this.buttonVariant = buttonVariant;
    });

    this._store.select(RouterSelectors.dataByKey<NavigationLink[]>('navigationLinks')).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe((navigationLinks) => {
      if (navigationLinks) {
        this.navigationLinks = navigationLinks;
      } else {
        this.navigationLinks = [];
      }
    });

    combineLatest([
      this._store.select(InhaberEntitiesSelectors.currentInhaberId),
      this._store.select(InhaberEntitiesSelectors.refInhaberId),
      this._store.select(InhaberEntitiesSelectors.fullInhaberList),
    ]).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(([currentInhaberId, refInhaberId, fullInhaberList]) => {
      if (currentInhaberId) {
        this.inhaberId = currentInhaberId;
      } else if (refInhaberId) {
        this.inhaberId = refInhaberId;
      } else if (fullInhaberList.length > 0) {
        this.inhaberId = fullInhaberList[0].id;
      }
    });

    this._store.select(RouterSelectors.url).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(url => {
      if (!url) return;
      this._showIconLinkWrapper = !url.includes('/overview') && !url.includes('/betrieb-not-found');
    });
  }

  doButtonNavigation(): void {
    if (this.buttonRoute) {
      this._router.navigateByUrl(this.buttonRoute.replace(':inhaber', this.inhaberId || ''));
    }
  }

}
