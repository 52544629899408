import {createReducer, on} from '@ngrx/store';
import {firmaEntitiesAdapter} from '../adapters/firma-entities.adapters';
import {FirmaEntitiesState} from '../states/firma-entities.state';
import {FirmaEntitiesActions} from '../actions/firma-entities.actions';


export const initialFirmaEntitiesState: FirmaEntitiesState = firmaEntitiesAdapter.getInitialState(
  {
    firmenLoaded: false,
  }
);

export const firmaEntitiesReducer = createReducer(
  initialFirmaEntitiesState,

  on(FirmaEntitiesActions.readFirmaSuccess, (state, {firmaDto}) => {
    return firmaEntitiesAdapter.upsertOne(
      firmaDto,
      {
        ...state,
      });
  }),

  on(FirmaEntitiesActions.readFirmenSuccess, (state, {firmenDtos: responseDtos}) => {
    return firmaEntitiesAdapter.upsertMany(
      responseDtos,
      {
        ...state,
        firmenLoaded: true
      });
  }),
);
