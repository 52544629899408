import {createReducer, on} from '@ngrx/store';
import {FinanzamtEntitiesState} from '../states/finanzamt-entities.state';
import {finanzamtEntitiesAdapter} from '../adapters/finanzamt-entities.adapters';
import {FinanzamtEntitiesActions} from '../actions/finanzamt-entities.actions';


export const initialFinanzamtEntitiesState: FinanzamtEntitiesState = finanzamtEntitiesAdapter.getInitialState(
  {
    finanzaemterLoaded: false,
  }
);

export const finanzamtEntitiesReducer = createReducer(
  initialFinanzamtEntitiesState,

  on(FinanzamtEntitiesActions.readFinanzaemterSuccess, (state, {finanzamtDtos}) => {
    return finanzamtEntitiesAdapter.upsertMany(
      finanzamtDtos,
      {
        ...state,
        finanzaemterLoaded: true,
      });
  }),
);
