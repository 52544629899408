import {inject, Injectable, NgZone, OnDestroy} from '@angular/core';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {NGXLogger} from 'ngx-logger';
import {EMPTY, Observable, of, Subscription} from 'rxjs';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {PortalMessageBroker} from './portal-message-broker';


@Injectable({
  providedIn: 'root'
})
export class AdnovaTokenService implements OnDestroy {

  private _logger = inject(NGXLogger);
  private _zone = inject(NgZone);
  private _portalMessageBroker = inject(PortalMessageBroker);

  private _token?: string;
  private _tokenUpdate?: Subscription;

  private startPeriodicObservale(
    repeatAfterSeconds: number,
  ): Observable<any> {

    const millisecondsDelayBetweenTokenCheck = repeatAfterSeconds * 1000;
    return new Observable((subscriber) => {
      let intervalId: any;
      this._zone.runOutsideAngular(() => {
        intervalId = setInterval(() => this._zone.run(() => subscriber.next()), millisecondsDelayBetweenTokenCheck);
      });
      return () => {
        clearInterval(intervalId);
      };
    });
  }

  private hasAdnovaJwtExpired(
    offsetSeconds?: number,
  ): boolean {
    if (!this._token || this._token.length < 1) {
      return true;
    }

    const decodedToken = jwtDecode<JwtPayload>(this._token);
    return !this.validateTokenNotExpired(decodedToken, offsetSeconds);
  }

  private validateTokenNotExpired(
    decodedToken: JwtPayload,
    offsetSeconds?: number,
  ): boolean {
    offsetSeconds = offsetSeconds || 0;

    // INFO: see TokenHelperService
    // https://github.com/damienbod/angular-auth-oidc-client/blob/version-13/projects/angular-auth-oidc-client/src/lib/utils/tokenHelper/token-helper.service.ts
    const exp = decodedToken.exp;
    if (!exp) {
      return false;
    }
    const tokenExpirationDate = new Date(0); // The 0 here is the key, which sets the date to the epoch
    tokenExpirationDate.setUTCSeconds(exp);

    const tokenExpirationValue = tokenExpirationDate.valueOf();
    const nowWithOffset = new Date(new Date().toUTCString()).valueOf() + offsetSeconds * 1000;
    const tokenNotExpired = tokenExpirationValue > nowWithOffset;

    this._logger.trace(`AdnovaTokenService.validateTokenNotExpired(): has adnova token expired: ${!tokenNotExpired}, ${tokenExpirationValue} > ${nowWithOffset}`);
    return tokenNotExpired;
  }

  private updateTokenInternal(
    token: string,
  ): void {
    this._token = token;
    this._logger.debug('AdnovaTokenService.updateTokenInternal(): adnova _token updated', this._token);
  }

  public startTokenRefresh(
    repeatAfterSeconds: number,
    renewTimeBeforeTokenExpiresInSeconds: number,
  ): Promise<any> {
    if (!renewTimeBeforeTokenExpiresInSeconds) {
      renewTimeBeforeTokenExpiresInSeconds = 30;
    }
    if (!this._tokenUpdate) {
      this._tokenUpdate = this.startPeriodicObservale(repeatAfterSeconds)
        .pipe(
          switchMap(() => {
            if (this.hasAdnovaJwtExpired(renewTimeBeforeTokenExpiresInSeconds)) {
              return this._portalMessageBroker.requestAdnovaToken()
                .pipe(
                  catchError(err => {
                    this._logger.error('AdnovaTokenService.startTokenRefresh(): adnova _token request failed: ', err);
                    return EMPTY;
                  }),
                );
            }
            return of('');
          }),
          filter(value => {
            if (value && value.length > 0) {
              return true;
            }
            return false;
          }),
          catchError(err => {
            this._logger.error('AdnovaTokenService.startTokenRefresh(): periodical adnova _token refresh failed: ', err);
            return EMPTY;
          })
        )
        .subscribe(
          value => this.updateTokenInternal(value),
          error => this._logger.error('AdnovaTokenService.startTokenRefresh(): could not refresh adnova _token', error)
        );

      this._logger.debug('AdnovaTokenService.startTokenRefresh(): adnova _token refresh started');
      return this._portalMessageBroker.requestAdnovaToken()
        .pipe(
          tap(token => {
            this.updateTokenInternal(token);
            this._logger.debug('AdnovaTokenService.startTokenRefresh(): initial adnova _token arrived');
          })
        ).toPromise();
    }
    this._logger.debug('AdnovaTokenService.startTokenRefresh(): adnova _token refresh already started, ignoring');
    return of(this.getToken())
      .toPromise();
  }

  public stopTokenRefresh(): void {
    if (this._tokenUpdate) {
      this._tokenUpdate.unsubscribe();
      this._tokenUpdate = undefined;
    }
    this._token = undefined;
  }

  public getToken(): string {
    if (!this._tokenUpdate) {
      throw new Error('_token refresh is not running');
    }
    if (!this._token) {
      throw new Error('_token not present');
    }
    return this._token;
  }

  ngOnDestroy(): void {
    this.stopTokenRefresh();
  }
}
