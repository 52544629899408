import {Actions, createEffect, ofType} from '@ngrx/effects';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NGXLogger} from 'ngx-logger';
import {Injectable} from '@angular/core';
import {FirmenService} from '../../openapi/partner-openapi';
import {FirmaEntitiesActions} from '../actions/firma-entities.actions';
import {catchError, concatMap, filter, map, switchMap} from 'rxjs/operators';
import {of, take} from 'rxjs';
import {mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {Router} from '@angular/router';
import {SentryActions} from '../actions/sentry.actions';
import {FirmaEntitiesSelectors} from '../selectors/firma-entities.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../states/app.state';


@Injectable()
export class FirmaEntitiesEffects {

  constructor(
    private actions$: Actions,
    private firmenService: FirmenService,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private router: Router,
    private store: Store<AppState>,
  ) {
  }

  /**
   * Effekt zum Laden eines Absenders anhand der Id, sofern es noch nicht im Store vorhanden ist.
   */
  readonly loadFirmaByIdIfAbsent$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.loadFirmaByIdIfAbsent),
      concatMap(action => this.store.select(FirmaEntitiesSelectors.firmaById(action.firmaId)).pipe(
        take(1),
        map(firmaDto => ({action, firmaDto}))
      )),
      filter(data => !data.firmaDto),
      map(data => FirmaEntitiesActions.readFirma({
        firmaId: data.action.firmaId,
      })),
    ),
  );

  /**
   * Lädt die Firma zur Id vom Service.
   */
  readonly readFirma$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.readFirma),
      switchMap(({
                   firmaId
                 }) => {
        return this.firmenService.readFirma(firmaId).pipe(
          map(firmaDto => {

            this.logger.debug(
              'read firma succeeded. firmaId: ',
              firmaId,
            );

            return FirmaEntitiesActions.readFirmaSuccess({
              firmaDto,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            concatMap(error => {
              this.logger.error(
                'read firma failed. error: ',
                error,
              );

              return [
                FirmaEntitiesActions.readFirmaFailure({
                  error: error,
                }),
                SentryActions.captureException({
                  error,
                  extras: {}
                }),
              ];
            }),
          ))
        );
      }),
    ),
  );

  /**
   * Error-Handling für das Laden einer Firma.
   */
  readonly readFirmaFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.readFirmaFailure),
      map(({
             error,
           }) => {

        let errorMsg = '';

        switch (error.status) {
          case 403: {
            errorMsg = 'Fehlende Berechtigungen für das Laden des Betriebs. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404: {
            errorMsg = 'Betrieb nicht gefunden. Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden des Betriebs. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
          });
      }),
    ), {dispatch: false}
  );

  /**
   * Lädt alle Firmen des Akteurs vom Service.
   */
  readonly readFirmen$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.readFirmen),
      switchMap(() => {
        return this.firmenService.readFirmenExtendedList().pipe(
          map(firmenDtos => {

            this.logger.debug(
              'read firmen succeeded',
            );

            // INFO: Sollte die Liste leer sein, hat der Anwender nicht die nötigen Berechtigungen.
            if (firmenDtos.length === 0) {
              this.router.navigateByUrl('betrieb-not-found');
            }

            return FirmaEntitiesActions.readFirmenSuccess({
              firmenDtos,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'read firmen failed. error: ',
                error,
              );

              return FirmaEntitiesActions.readFirmenFailure({
                error: error,
              });
            }),
          ))
        );
      }),
    ),
  );

  /**
   * Error-Handling für das Laden von Firmen.
   */
  readonly readFirmenFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.readFirmenFailure),
      map(({
             error,
           }) => {

        let errorMsg = '';

        switch (error.status) {
          case 403: {
            errorMsg = 'Fehlende Berechtigungen für das Laden der Betriebe. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404: {
            errorMsg = 'Keine Betriebe nicht gefunden. Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden der Betriebe. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
          });
      }),
    ), {dispatch: false}
  );

  /**
   * Aktualisieren einer Firma.
   */
  readonly updateFirma$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.updateFirma),
      switchMap(({
                   firmaId,
                   requestDto,
                 }) => {

        return this.firmenService.updateFirma(firmaId, requestDto).pipe(
          map(() => {
            this.logger.debug(
              'update firma succeeded',
            );

            this.snackbar.open(
              'Betrieb erfolgreich aktualisiert.',
              undefined,
              {
                duration: 5000,
              });

            // INFO: Nach dem Aktualisieren wird der Anwender auf die Übersichtsseite weitergeleitet.
            this.router.navigateByUrl('overview');

            // INFO: Nach dem Aktualisieren wird die Firma neu geladen.
            return FirmaEntitiesActions.readFirma({firmaId});
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            concatMap(error => {
              this.logger.error(
                'update firma failed. error: ',
                error,
                'firmaId: ',
                firmaId,
              );

              return [
                FirmaEntitiesActions.updateFirmaFailure({
                  error: error,
                }),
                SentryActions.captureException({
                  error,
                  extras: {}
                }),
              ];
            }),
          ))
        );
      }),
    ),
  );

  /**
   * Error-Handling für das Aktualisieren einer Firma.
   */
  readonly updateFirmaFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.updateFirmaFailure),
      map(({
             error,
           }) => {

        let errorMsg = '';

        switch (error.status) {
          case 403: {
            errorMsg = 'Fehlende Berechtigungen für das Aktualisieren des Betriebs. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404: {
            errorMsg = 'Benötigte Daten für das Aktualisieren des Betriebs nicht gefunden. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 409: {
            errorMsg = error.error + ' ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Aktualisieren des Betriebs. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
          });
      }),
    ), {dispatch: false}
  );

}
